import React from 'react'

const ReportStateContext = React.createContext(undefined)

ReportStateContext.displayName = 'CaseSearchStateContext'

const ReportDispatchContext = React.createContext(undefined)

ReportDispatchContext.displayName = 'ReportDispatchContext'

export { ReportDispatchContext, ReportStateContext }
