import { Route, useLocation, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'
import { ProtectedElement } from '@praxis/component-auth'
import { ErrorContent } from '../globalComponents/ErrorContent'
import { LayoutSimple } from '../views/LayoutSimple'
import { CreditDebitReport } from '../views/CreditDebitReport'
import { ReportContextProvider } from '../views/ReportContext/ReportContextProvider'
import { useEnv } from '@praxis/component-runtime-env'
import { ReceiptDetailReport } from '../views/ReceiptDetailReport'
import { ReceiptByPOReport } from '../views/ReceiptByPOReport'
import { PreMatchReport } from '../views/PreMatchReport'
import { AfterMatchReport } from '../views/AfterMatchReport'

export const MainRouter = () => {
  const location = useLocation()
  const { auth } = useEnv()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route exact path="/" element={<LayoutSimple />}></Route>
        <Route
          exact
          path="/credit_debit"
          allowed={[auth.userRole, auth.vendorRole].flat()}
          element={
            <ProtectedElement>
              <ReportContextProvider>
                <CreditDebitReport />
              </ReportContextProvider>
            </ProtectedElement>
          }
        />
        <Route
          exact
          path="/receipt_detail"
          allowed={[auth.userRole].flat()}
          element={
            <ProtectedElement>
              <ReportContextProvider>
                <ReceiptDetailReport />
              </ReportContextProvider>
            </ProtectedElement>
          }
        />
        <Route
          exact
          path="/receipt_by_po"
          allowed={[auth.userRole].flat()}
          element={
            <ProtectedElement>
              <ReportContextProvider>
                <ReceiptByPOReport />
              </ReportContextProvider>
            </ProtectedElement>
          }
        />

        <Route
          exact
          path="/pre_match"
          allowed={[auth.userRole].flat()}
          element={
            <ProtectedElement>
              <ReportContextProvider>
                <PreMatchReport />
              </ReportContextProvider>
            </ProtectedElement>
          }
        />

        <Route
          exact
          path="/after_match"
          allowed={[auth.userRole].flat()}
          element={
            <ProtectedElement>
              <ReportContextProvider>
                <AfterMatchReport />
              </ReportContextProvider>
            </ProtectedElement>
          }
        />
      </Routes>
    </ErrorBoundary>
  )
}
