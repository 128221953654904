import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { EnvProvider } from '@praxis/component-runtime-env'
import { AuthProvider } from '@praxis/component-auth'

import './globalStyles/customStyles.css'
import { Main } from './views/Main'
import apiConfig from './globalConfig/apiConfig'
import { UserProfileProvider } from './globalComponents/UserProfileProvider'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

const App = () => {
  return (
    <EnvProvider
      commonConfig={apiConfig}
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {({ env }) => {
        return (
          <>
            <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
            <Router>
              <AuthProvider {...env.auth}>
                <UserProfileProvider>
                  <ToastProvider>
                    <Main />
                  </ToastProvider>
                </UserProfileProvider>
              </AuthProvider>
            </Router>
          </>
        )
      }}
    </EnvProvider>
  )
}

export default App
