import React from 'react'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import UserProfileContext from './UserProfileContext'
import { LayoutBody } from '@enterprise-ui/canvas-ui-react'
import LogIn from '../views/LogIn'

export const USER = 'USER'
export const VENDOR = 'VENDOR'

export const UserProfileProvider = (props) => {
  const { session, isAuthorized, isAuthenticated } = useAuth()
  const { auth } = useEnv()

  function getUserRole() {
    if (isAuthorized(auth.userRole)) {
      return USER
    }

    if (isAuthorized(auth.vendorRole)) {
      return VENDOR
    }

    console.log(session?.userInfo?.memberOf)
    return undefined
  }

  if (!isAuthenticated()) {
    return <LogIn />
  }

  if (getUserRole() === undefined) {
    return (
      <LayoutBody>
        <h1>Unauthorized :</h1>
        <h4>Please get the correct access to use this application</h4>
      </LayoutBody>
    )
  }

  return (
    <UserProfileContext.Provider value={{ userRole: getUserRole() }}>
      {props.children}
    </UserProfileContext.Provider>
  )
}

export function useProfile() {
  const context = React.useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error(`useProfile must be used within a Use Profile Provider`)
  }
  return context
}
