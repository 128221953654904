import { useState } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import axios from 'axios'
import { MainRouter } from '../globalComponents/MainRouter'
import { Header } from '../globalComponents/Header'
import { SideNavigation } from '../globalComponents/SideNavigation'
import { isSSOSessionActive } from './AuthUtils'

export const Main = () => {
  const auth = useAuth()

  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen)
  }

  axios.interceptors.request.use(function (config) {
    if (isSSOSessionActive()) {
      // add the authorization to the headers

      config.headers.common['Authorization'] =
        localStorage.getItem('access_token') || null
    } else {
      auth.login()
    }
    return config
  })

  return (
    <Layout data-testid="mainAuthenticatedLayout">
      <Header onSideNavToggle={handleSideNavToggle} />
      <SideNavigation
        isOpen={isSideNavOpen}
        onRequestClose={handleSideNavToggle}
      />
      <MainRouter />
    </Layout>
  )
}
