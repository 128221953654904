import React from 'react'
import { useImmerReducer } from 'use-immer'
import { SET_REPORT, SET_REPORT_RESULT } from './ActionTypes'
import { ReportDispatchContext, ReportStateContext } from './ReportContext'

function reportReducer(draft, action) {
  switch (action.type) {
    case SET_REPORT: {
      draft.report = action.payload
      return
    }

    case SET_REPORT_RESULT: {
      draft.reportData = action.payload
      return
    }
    default: {
      return
    }
  }
}

const initialValue = {
  report: {},
  reportData: {},
}

const ReportContextProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reportReducer, initialValue)
  return (
    <ReportStateContext.Provider value={state}>
      <ReportDispatchContext.Provider value={dispatch}>
        {props.children}
      </ReportDispatchContext.Provider>
    </ReportStateContext.Provider>
  )
}

function useReportState() {
  const context = React.useContext(ReportStateContext)
  if (context === undefined) {
    throw new Error(
      'useReportState must be used within a ReportContextProvider',
    )
  }
  return context
}

function useReportDispatch() {
  const context = React.useContext(ReportDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useReportDispatch must be used within a ReportContextProvider',
    )
  }
  return context
}

function useReport() {
  return [useReportState(), useReportDispatch()]
}

export { useReport, ReportContextProvider }
