import { useState, useEffect } from 'react'
import {
  Card,
  Grid,
  Layout,
  Form,
  FormField,
  Button,
  Tabs,
  GridContainer,
  GridItem,
  Spinner,
  Heading,
  Dropdown,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'

import { SectionHeader } from '../globalComponents/SectionHeader'
import axios from 'axios'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useFormik } from 'formik'
import { CreditDebitTable } from './CreditDebitTable'
import { useReport } from './ReportContext/ReportContextProvider'
import { SET_REPORT, SET_REPORT_RESULT } from './ReportContext/ActionTypes'
import _ from 'lodash'
import { convertToISO, convertToUS } from '../globalComponents/Utils'
import * as Yup from 'yup'
import jsFileDownload from 'js-file-download'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { useProfile, VENDOR } from '../globalComponents/UserProfileProvider'
import '@enterprise-ui/canvas-ui-css' // wherever this is in your app
import '@enterprise-ui/canvas-ui-css-datepicker' // add this line below

export const CreditDebitReport = () => {
  const [vendors, setVendors] = useState()
  const [state, dispatch] = useReport()
  const [activeTab, setActiveTab] = useState()
  const [loading, setLoading] = useState()
  const [showExportSpinner, setShowExportSpinner] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('Loading Data')
  const [limitMessage, setLimitMessage] = useState('')
  const [vendorName, setVendorName] = useState('')
  const env = useEnv()
  const { session } = useAuth()
  const { userRole } = useProfile()
  const makeToast = useToaster()

  const bpurl = `${env.api.baseUrl}/vendors?business_partner_id=${session.userInfo.lanId}`
  const vendorUrl = `${env.api.baseUrl}/vendors/`
  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))

  let newDate = new Date()

  const cDesc = `${'Note: Document Number Can be found on the Payment Remittance. '}${'\n'}${' Entering Begin date will dislay all the credit memos for 7 days'}`
  var isVendor = userRole === VENDOR ? 'Y' : 'N'

  useEffect(() => {
    const vData = ''
    axios
      .get(`${env.api.baseUrl}/report_configs/credit_debit`)
      .then((res) => {
        res?.data?.tabs?.map((tabConfig, indexToInsert) => {
          if (tabConfig['tab_name'] === 'inbound') {
            let indexToInsert = 4
            tabConfig['table_mappings'].map((treemap, index) => {
              if (treemap.accessor === 'associated_invoice_date') {
                indexToInsert = index
              }
              return treemap
            })

            const receiptIdsObj = {
              label: 'receipts #',
              accessor: 'receipt_ids',
              size: 2,
            }

            const invoiceIdsObj = {
              label: 'invoices #',
              accessor: 'invoice_ids',
              size: 2,
            }
            if (indexToInsert !== -1) {
              tabConfig['table_mappings'].splice(
                indexToInsert + 1,
                0,
                receiptIdsObj,
              )
              tabConfig['table_mappings'].splice(
                indexToInsert + 2,
                0,
                invoiceIdsObj,
              )
            }
          }
          return tabConfig
        })
        dispatch({ type: SET_REPORT, payload: res.data })
      })
      .catch(() => {
        makeToast({
          autoHideDuration: 100000000,
          type: 'error',
          heading: 'Configuration Error',
          message:
            'Unable to fetch the credit-debit configurations. The credit-debit report might not work properly. Please refresh the page or report the issue if it persists.',
        })
      })

    if (isVendor === 'Y') {
      axios
        .get(bpurl)
        .then((res) => {
          const venddetails = res.data.map((vendorsdata) => {
            return {
              value: vendorsdata.gmsVendorNumber,
              label: `${vendorsdata.gmsVendorNumber}${' ('}${
                vendorsdata.vendorName
              }${')'}`,
            }
          })
          setVendors(venddetails)
        })
        .catch(() => {
          alert('No Vendors associated with BP id')
          setLoading(false)
          setVendors([{ vData }])
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {
      vendor_id: undefined,
      document_id: undefined,
      begin_date: undefined,
      document_type: undefined,
    },
    validationSchema: Yup.object(
      {
        vendor_id: Yup.string().required('Vendor id is a required field'),
        document_id: Yup.string()
          .test(
            'notBothAtTheSameTime',
            'You cannot pass Document Id at the same time as Begin Date (upto 2 years)',
            function (document_id) {
              const { begin_date } = this.parent
              if (document_id && begin_date) {
                return false
              }
              if (
                begin_date &&
                diffDays(new Date(newDate), new Date(begin_date)) > 738
              ) {
                setLimitMessage('Limit the Start Date to 2 years')
                return false
              }
              setLimitMessage('')
              return true
            },
          )
          .when(['begin_date'], {
            is: (begin_date) => !begin_date,
            then: Yup.string().required(
              'Either one of Document Number or Begin date is required',
            ),
          }),
      },
      ['document_id', 'begin_date'],
    ),
    onSubmit: (values) => {
      setLoading(true)
      setLoadingMessage('Loading Data')
      axios
        .post(`${env.api.baseUrl}/execute_report`, {
          report_name: 'credit_debit',
          params: values,
        })
        .then((res) => {
          const vNameURL = `${vendorUrl}${values.vendor_id}`
          setActiveTab(res.data?.tab_name)

          if (res.data?.tab_name === 'inbound') {
            if (res.data.result?.length) {
              res.data.result.map((itemInfo) => getInboundResults(itemInfo))
            }
          }

          dispatch({ type: SET_REPORT_RESULT, payload: res.data })
          axios
            .get(vNameURL)
            .then((res) => {
              setVendorName(res.data.vendor_name)
            })
            .catch(() => {
              setVendorName('Invalid Vendor Id')
            })
          setLoading(false)
        })
        .catch((error) => {
          dispatch({ type: SET_REPORT_RESULT, payload: undefined })
          showToasterNoDataError()
          setLoading(false)
        })
    },
  })

  const getInboundResults = (itemInfo) => {
    const invoiceIds = []
    const receiptIds = []
    const invoiceDetails = itemInfo['total_invoices_location_date'].split(',')
    const receiptDetails = itemInfo['total_receipts_location_date'].split(',')
    invoiceDetails.map((invoice) => {
      const invoiceInfo = invoice.split('|')
      if (invoiceInfo.length === 6 && invoiceInfo[1] !== 'null') {
        invoiceIds.push(invoiceInfo[1])
      }
      return invoice
    })
    receiptDetails.map((receipt) => {
      const receiptInfo = receipt.split('|')
      if (receiptInfo.length === 4 && receiptInfo[1] !== 'null') {
        receiptIds.push(receiptInfo[1])
      }
      return receipt
    })
    itemInfo['receipt_ids'] = receiptIds
    itemInfo['invoice_ids'] = invoiceIds
    return itemInfo
  }

  const handleExcelDownload = () => {
    setShowExportSpinner(false)
    setLoadingMessage('Downloading Data to Excel...')

    axios
      .post(
        `${env.api.baseUrl}/extract_report`,
        {
          report_name: 'credit_debit',
          params: formik.values,
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        jsFileDownload(res.data, 'Report.xlsx')
        setShowExportSpinner(true)
      })
      .catch(() => {
        showToasterDownloadError()
        setShowExportSpinner(true)
      })
  }

  const showToasterDownloadError = () => {
    makeToast({
      type: 'error',
      autoHideDuration: 6000,
      heading: 'Export Error',
      message: 'Unable to Export data to file. Please try again.',
    })
  }

  const showToasterNoDataError = () => {
    makeToast({
      type: 'alert',
      autoHideDuration: 6000,
      heading: 'Data Not Found',
      message: 'No Data Available for the provided Inputs.',
      location: 'top',
    })
  }

  const handlePDFDownload = () => {
    setShowExportSpinner(false)
    setLoadingMessage('Downloading Data to PDF...')
    axios
      .post(
        `${env.api.baseUrl}/pdf_report`,
        {
          report_name: 'credit_debit',
          params: formik.values,
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        jsFileDownload(res.data, 'Report.pdf')
        setShowExportSpinner(true)
      })
      .catch(() => {
        showToasterNoDataError()
        setShowExportSpinner(true)
      })
  }

  return (
    <>
      <SectionHeader pageHeading="Credit Debit Report" />
      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        <Card>
          <div className="hc-pa-dense">
            <Form onSubmit={formik.handleSubmit}>
              <Grid.Container align="center" justify="center">
                <Grid.Item xs={12} className={'hc-pb-none'}>
                  <Grid.Container align="flex-start" justify="space-between">
                    <Grid.Item xs={4}>
                      {isVendor === 'Y' ? (
                        <FormField
                          id="vendor_id"
                          label="Vendor Id"
                          type="select"
                          options={vendors}
                          onUpdate={formik.setFieldValue}
                          value={formik.values.vendor_id}
                          errorText={formik.errors.vendor_id}
                          error={
                            formik.errors.vendor_id && formik.touched.vendor_id
                          }
                        />
                      ) : (
                        <FormField
                          id="vendor_id"
                          label="Enter Vendor Id"
                          onChange={formik.handleChange}
                          value={formik.values.vendor_id}
                          errorText={formik.errors.vendor_id}
                          hintText={vendorName}
                          error={
                            formik.errors.vendor_id && formik.touched.vendor_id
                          }
                        />
                      )}
                    </Grid.Item>
                    <Grid.Item xs={3}>
                      <FormField
                        id="document_id"
                        label="Enter Document Number"
                        onChange={formik.handleChange}
                        value={_.toUpper(formik.values.document_id)}
                        errorText={formik.errors.document_id}
                        error={
                          formik.errors.document_id !== undefined &&
                          formik.touched.document_id
                        }
                      />
                    </Grid.Item>
                    <Grid.Item xs={3}>
                      <DatePicker
                        id="begin_date"
                        label="Start Date"
                        onUpdate={(id, value) => {
                          formik.setFieldValue(id, convertToISO(value))
                          formik.handleChange(id, value)
                        }}
                        value={convertToUS(formik.values.begin_date)}
                        errorText={formik.errors.begin_date}
                        error={
                          formik.errors.begin_date !== undefined &&
                          formik.touched.begin_date
                        }
                        hintText={limitMessage}
                      />
                    </Grid.Item>
                    <Grid.Item
                      xs={2}
                      className="hc-mb-normal"
                      style={{ paddingTop: '30px' }}
                    >
                      <Button type="submit">Submit</Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
                <GridItem xs={12} className={'hc-pv-none'}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex',
                      fontFamily: 'verdana',
                      fontSize: '11px',
                      color: 'gray',
                      padding: '10px 0',
                    }}
                  >
                    {cDesc}
                  </div>
                </GridItem>
                <GridItem xs={3}>
                  {!showExportSpinner && (
                    <div
                      style={{
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Heading size={6}>
                        <Spinner size="dense"></Spinner> {loadingMessage}
                      </Heading>
                    </div>
                  )}
                </GridItem>
                {!loading && (
                  <>
                    <GridItem xs={12} className={'hc-pv-none'}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Dropdown location="bottom-left">
                          <Button type="secondary">Export As</Button>
                          <Dropdown.Menu>
                            <Dropdown.MenuItem
                              onClick={() => handleExcelDownload()}
                            >
                              {' '}
                              Excel
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem
                              onClick={() => handlePDFDownload()}
                            >
                              PDF
                            </Dropdown.MenuItem>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </GridItem>
                    <Grid.Item xs={12} className={'hc-pt-none'}>
                      <Tabs
                        activeTab={activeTab}
                        onTabSelect={(event, tab) => {
                          event.preventDefault()
                          formik.setFieldValue('document_type', tab?.name)
                          formik.submitForm()
                          setActiveTab(tab?.name)
                        }}
                      >
                        {state.report?.tabs?.map((tab) => {
                          return (
                            <Tabs.Item name={tab?.tab_name}>
                              {tab?.tab_display_name}
                            </Tabs.Item>
                          )
                        })}
                        {state.report?.tabs?.map((tab) => {
                          return (
                            <Tabs.Content
                              name={tab?.tab_name}
                              key={tab?.tab_name}
                            >
                              <GridContainer>
                                <GridItem xs="12">
                                  {state.reportData?.result !== undefined && (
                                    <CreditDebitTable
                                      tab={tab}
                                      data={_.map(
                                        _.uniqBy(
                                          state.reportData?.result,
                                          tab?.tree_mapping_id,
                                        ) ?? [],
                                        function (element) {
                                          return _.extend({}, element, {
                                            id: element.documet_number,
                                          })
                                        },
                                      )}
                                      treeData={_.groupBy(
                                        state.reportData?.result,
                                        tab?.tree_mapping_id,
                                      )}
                                    />
                                  )}
                                </GridItem>
                              </GridContainer>
                            </Tabs.Content>
                          )
                        })}
                      </Tabs>
                    </Grid.Item>
                  </>
                )}
                {loading && (
                  <Grid.Item xs={1}>
                    <Spinner dense expanded />
                  </Grid.Item>
                )}
                {state.reportData?.report_name !== undefined &&
                  state.reportData?.result === undefined &&
                  !loading && (
                    <GridItem>
                      <p>Data Not Found</p>
                    </GridItem>
                  )}
              </Grid.Container>
            </Form>
          </div>
        </Card>
      </Layout.Body>
    </>
  )
}
