import { useState } from 'react'
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import {
  Card,
  Grid,
  Layout,
  Form,
  FormField,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { SectionHeader } from '../globalComponents/SectionHeader'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import jsFileDownload from 'js-file-download'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const loadingPosition = css`
  height: 50vh;
`

export const ReceiptDetailReport = () => {
  const env = useEnv()
  const [showExportSpinner, setShowExportSpinner] = useState(true)
  const [loadingMessage] = useState('Downloading Data to Excel...')
  const makeToast = useToaster()

  const schema = Yup.object().shape(
    {
      start_date: Yup.date()
        .test('Fine', 'Please enter Start Date', function (value) {
          const context = this.options.context
          if (value === undefined && context?.end_date) {
            return false
          }
          return true
        })
        .min(
          moment().startOf('year').subtract('years', 2).format('YYYY-MM-DD'),
          `Start date should be later than : ${moment()
            .startOf('year')
            .subtract('years', 2)
            .format('MM/DD/yyyy')} `,
        )
        .max(
          moment().format('YYYY-MM-DD'),
          'Start date should not be later than current date',
        ),
      end_date: Yup.date()
        .test('Fine', 'Please enter End Date', function (value) {
          const context = this.options.context
          if (value === undefined && context?.start_date) {
            return false
          }
          return true
        })
        .min(Yup.ref('start_date'), `End date should be later than start date`)
        .max(
          moment().format('YYYY-MM-DD'),
          'End date should not be later than current date',
        ),
      vendor_id: Yup.number().required('Enter Vendor Id'),
      loc: Yup.number().when('po_id', (poValue) => {
        if (!poValue) {
          return Yup.number().required('Please enter either Location or PO')
        }
      }),
      po_id: Yup.number().when('loc', (locValue) => {
        if (!locValue) {
          return Yup.number().required('Please enter either Location or PO')
        }
      }),
      receipt_id: Yup.number().required('Enter Receipt Id'),
    },
    ['loc', 'po_id', 'start_date', 'end_date', 'vendor_id', 'receipt_id'],
  )

  const formik = useFormik({
    initialValues: {},
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: (values) => {
      setShowExportSpinner(false)
      let requestParams = {
        vendor_id:
          values?.vendor_id !== undefined
            ? values?.vendor_id !== ''
              ? values?.vendor_id
              : null
            : null,
        start_date:
          values?.start_date !== undefined
            ? values?.start_date !== ''
              ? values?.start_date
              : null
            : null,
        end_date:
          values?.end_date !== undefined
            ? values?.end_date !== ''
              ? values?.end_date
              : null
            : null,
        po_id:
          values?.po_id !== undefined
            ? values?.po_id !== ''
              ? values?.po_id
              : null
            : null,
        loc:
          values?.loc !== undefined
            ? values?.loc !== ''
              ? values?.loc
              : null
            : null,
        receipt_id:
          values?.receipt_id !== undefined
            ? values?.receipt_id !== ''
              ? values?.receipt_id
              : null
            : null,
      }
      axios
        .post(
          `${env.api.baseUrl}/extract_internal_report`,
          {
            report_name: 'receipt_detail',
            params: requestParams,
          },
          { responseType: 'blob' },
        )
        .then((res) => {
          jsFileDownload(res.data, 'ReceiptDetailReport.xlsx')
          setShowExportSpinner(true)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            autoHideDuration: 6000,
            heading: 'Export Error',
            message: 'Unable to Export data to file. Please try again.',
          })
          setShowExportSpinner(true)
        })
    },
  })

  return (
    <>
      <SectionHeader pageHeading="Receipt Detail Report" />
      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        <Card>
          <div className="hc-pa-normal hc-pa-md">
            <Form onSubmit={formik.handleSubmit}>
              <Grid.Container justify="space-between" className="hc-mt-normal">
                <Grid.Item xs={4}>
                  <DatePicker
                    id="start_date"
                    label="Start Date"
                    onUpdate={(id, value) => {
                      formik.setFieldValue(id, value)
                    }}
                    value={formik.values.start_date}
                    errorText={formik.errors.start_date}
                    error={formik.errors.start_date !== undefined}
                    location="bottom-right-left-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <DatePicker
                    id="end_date"
                    label="End Date"
                    onUpdate={(id, value) => {
                      formik.setFieldValue(id, value)
                    }}
                    value={formik.values.end_date}
                    errorText={formik.errors.end_date}
                    error={formik.errors.end_date !== undefined}
                    location="bottom-right-left-left-left"
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="vendor_id"
                    label="Enter Vendor Id"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.vendor_id}
                    errorText={formik.errors.vendor_id}
                    error={formik.errors.vendor_id}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={4}>
                  <FormField
                    id="loc"
                    label="Enter Location"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    value={formik.values.loc}
                    errorText={formik.errors.loc}
                    error={formik.errors.loc}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="po_id"
                    label="Enter PO"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 11)
                    }}
                    value={formik.values.po_id}
                    errorText={formik.errors.po_id}
                    error={formik.errors.po_id}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="receipt_id"
                    label="Enter Receipt"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.receipt_id}
                    errorText={formik.errors.receipt_id}
                    error={formik.errors.receipt_id}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button type="submit" raised>
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Form>
          </div>
        </Card>
        <Grid.Container justify="center" align="center" css={loadingPosition}>
          {!showExportSpinner && (
            <Grid.Item>
              <Spinner /> {loadingMessage}
            </Grid.Item>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
