import { useState } from 'react'

import {
  Card,
  Grid,
  Layout,
  Form,
  FormField,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { SectionHeader } from '../globalComponents/SectionHeader'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import jsFileDownload from 'js-file-download'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import * as Yup from 'yup'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const loadingPosition = css`
  height: 50vh;
`
export const AfterMatchReport = () => {
  const env = useEnv()
  const [showExportSpinner, setShowExportSpinner] = useState(true)
  const [loadingMessage] = useState('Downloading Data to Excel...')
  const makeToast = useToaster()

  function convertStringToList(listOfStrings) {
    if (listOfStrings.slice(-1) === ',') {
      listOfStrings = listOfStrings.slice(0, -1)
    }
    return listOfStrings
      .split(',')
      .map((formattedString) => formattedString.trim())
      .filter((formattedString) => formattedString !== '')
  }

  const schema = Yup.object().shape(
    {
      vendor_id: Yup.number().required('Please Enter Vendor Id'),
      invoice: Yup.string()
        .test('Fine', 'Please enter valid Invoice', function (value) {
          if (value !== undefined && value.slice(-1) === ',') {
            return false
          }
          return true
        })
        .test('Fine', 'Maximum 16 Invoices are allowed', function (value) {
          if (value !== undefined) {
            if (convertStringToList(value).length > 16) {
              return false
            }
          }
          return true
        })
        .test(
          'Fine',
          'Please enter Invoice or Match Object Id',
          function (value) {
            const { match_obj_id } = this.parent
            if (value !== undefined) {
              if (match_obj_id) {
                return false
              } else {
                return true
              }
            }
            if (value === undefined && match_obj_id) {
              return true
            }
            return false
          },
        ),
      match_obj_id: Yup.number().test(
        'Fine',
        'Please enter Invoice or Match Object Id',
        function (value) {
          const { invoice } = this.parent
          if (value !== undefined) {
            if (invoice) {
              return false
            } else {
              return true
            }
          }
          if (value === undefined && invoice) {
            return true
          }
          return false
        },
      ),
    },
    ['vendor_id', 'invoice'],
  )

  const formik = useFormik({
    initialValues: {
      vendor_id: undefined,
      invoice: undefined,
      match_obj_id: undefined,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: (values) => {
      let afterMatchRequest = {
        vendor_id:
          values?.vendor_id !== undefined
            ? values?.vendor_id !== ''
              ? values?.vendor_id
              : null
            : null,
        invoice:
          values?.invoice !== undefined
            ? values?.invoice !== ''
              ? convertStringToList(values?.invoice)
              : []
            : [],
        match_obj_id:
          values?.match_obj_id !== undefined
            ? values?.match_obj_id !== ''
              ? values?.match_obj_id
              : null
            : null,
      }
      console.log(afterMatchRequest)
      setShowExportSpinner(false)
      axios
        .post(
          `${env.api.baseUrl}/extract_internal_report`,
          {
            report_name: 'after_match',
            params: afterMatchRequest,
          },
          { responseType: 'blob' },
        )
        .then((res) => {
          jsFileDownload(res.data, 'AfterMatchReport.xlsx')
          setShowExportSpinner(true)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            autoHideDuration: 6000,
            heading: 'Export Error',
            message: 'Unable to Export data to file. Please try again.',
          })
          setShowExportSpinner(true)
        })
    },
  })

  return (
    <>
      <SectionHeader pageHeading="After Match Report" />
      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        <Card>
          <div className="hc-pa-normal hc-pa-md">
            <Form onSubmit={formik.handleSubmit}>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <Grid.Container>
                    <Grid.Item xs={4}>
                      <FormField
                        id="vendor_id"
                        type="number"
                        className="inputtype"
                        label="Enter Vendor Id"
                        onChange={formik.handleChange}
                        value={formik.values.vendor_id}
                        errorText={formik.errors.vendor_id}
                        error={formik.errors.vendor_id}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={12} className="hc-pt-none">
                  <Grid.Container>
                    <Grid.Item xs={12}>
                      <FormField
                        id="invoice"
                        label="Enter Invoice"
                        type="text"
                        size="expanded"
                        onChange={formik.handleChange}
                        value={formik.values.invoice}
                        errorText={formik.errors.invoice}
                        error={formik.errors.invoice}
                        rightContent="Enter Upto 16 Invoices"
                        placeholder="Please enter comma separated invoices "
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={12} className="hc-pt-none">
                  <Grid.Container>
                    <Grid.Item xs={4}>
                      <FormField
                        id="match_obj_id"
                        label="Enter Match Object Id"
                        type="number"
                        className="inputtype"
                        onChange={formik.handleChange}
                        value={formik.values.match_obj_id}
                        errorText={formik.errors.match_obj_id}
                        error={formik.errors.match_obj_id}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button type="submit" raised>
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Form>
          </div>
        </Card>
        <Grid.Container justify="center" align="center" css={loadingPosition}>
          {!showExportSpinner && (
            <Grid.Item>
              <Spinner /> {loadingMessage}
            </Grid.Item>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
