const convertToUS = (isoDate) => {
  const split = isoDate?.split('-')

  if (split && split.length > 1) {
    return `${split[1]}/${split[2]}/${split[0]}`
  }
  return undefined
}

const convertToISO = (usDate) => {
  const split = usDate?.split('/')

  if (split && split.length > 1) {
    return `${split[2]}-${split[0]}-${split[1]}`
  } else {
    return usDate
  }
}

export { convertToISO, convertToUS }
