import { useState } from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import {
  Card,
  Grid,
  Layout,
  Form,
  FormField,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { SectionHeader } from '../globalComponents/SectionHeader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import axios from 'axios'
import jsFileDownload from 'js-file-download'

export const ReceiptByPOReport = () => {
  const env = useEnv()
  const [showExportSpinner, setShowExportSpinner] = useState(true)
  const [loadingMessage] = useState('Downloading Data to Excel...')
  const makeToast = useToaster()

  function convertStringToList(poString) {
    if (poString.slice(-1) === ',') {
      poString = poString.slice(0, -1)
    }
    return poString
      .split(',')
      .map((po) => po.trim())
      .filter((po) => po !== '')
  }

  const formik = useFormik({
    initialValues: {
      vendor_id: undefined,
      po_id: undefined,
      department: undefined,
      start_date: undefined,
      end_date: undefined,
      loc: undefined,
    },
    validationSchema: Yup.object({
      start_date: Yup.date()
        .test('Fine', 'Please enter Start Date', function (value) {
          const { end_date } = this.parent
          if (value === undefined && end_date) {
            return false
          }
          return true
        })
        .min(
          moment().startOf('year').subtract(2, 'years').format('MM/DD/YYYY'),
          `Start date should be later than : ${moment()
            .startOf('year')
            .subtract(2, 'years')
            .format('MM/DD/yyyy')} `,
        )
        .max(
          moment().format('MM/DD/YYYY'),
          'Start date should not be later than current date',
        )
        .test(
          'BothDateRangeAndPONotEntered',
          'Please enter date range or PO',
          function (start_date) {
            const { po_id } = this.parent
            const { end_date } = this.parent
            const { department } = this.parent
            if (!start_date && !po_id && !end_date && department !== 1000) {
              return false
            }
            return true
          },
        ),
      end_date: Yup.date()
        .test('Fine', 'Please enter End Date', function (value) {
          const { start_date } = this.parent
          if (value === undefined && start_date) {
            return false
          }
          return true
        })
        .min(Yup.ref('start_date'), `End date should be later than start date`)
        .max(
          moment().format('YYYY-MM-DD'),
          'End date should not be later than current date',
        )
        .test(
          'POandDateRangeNotEntered',
          'Please enter date range or PO',
          function (end_date) {
            const { po_id } = this.parent
            const { start_date } = this.parent
            const { department } = this.parent
            if (!start_date && !po_id && !end_date && department !== 1000) {
              return false
            }
            return true
          },
        ),
      po_id: Yup.string()
        .when(['start_date', 'end_date'], {
          is: (start_date, end_date) => !start_date && !end_date,
          then: Yup.string().required('Please enter date range or PO'),
        })
        .test(
          'containsOnlyNumbersAndComma',
          'Please enter valid POs [0-9] with comma seperated.No whitespaces allowed',
          function (value) {
            if (!value || (value !== undefined && /^[0-9,]*$/.test(value))) {
              return true
            }
            return false
          },
        )
        .test(
          'expectedPOAfterComma',
          'Please enter valid PO',
          function (value) {
            if (value !== undefined && value.slice(-1) === ',') {
              return false
            }
            return true
          },
        )
        .test('Maximum5Allowed', 'Maximum 5 POs allowed', function (value) {
          if (value !== undefined) {
            if (convertStringToList(value).length > 5) {
              return false
            }
          }
          return true
        })
        .test(
          'POMandatoryForDept1000',
          'Please enter PO for dept 1000',
          function (value) {
            const { department } = this.parent
            if (
              (value === undefined || value === '') &&
              department &&
              department === 1000
            ) {
              return false
            }
            return true
          },
        ),
      department: Yup.number()
        .test('Fine', 'Please enter department', function (value) {
          const { po_id } = this.parent
          const { start_date } = this.parent
          const { end_date } = this.parent
          if (value === undefined && !po_id && !start_date && !end_date) {
            return false
          }
          return true
        })
        .test('Fine', 'Please enter PO or department', function (value) {
          const { po_id } = this.parent
          if (value !== undefined) {
            return true
          }
          if (value === undefined && po_id) {
            return true
          }
          return false
        }),
    }),
    onSubmit: (values) => {
      let receiptByPORequest = {
        vendor_id:
          values?.vendor_id !== undefined
            ? values?.vendor_id !== ''
              ? values?.vendor_id
              : null
            : null,
        start_date:
          values?.start_date !== undefined
            ? values?.start_date !== ''
              ? values?.start_date
              : null
            : null,
        end_date:
          values?.end_date !== undefined
            ? values?.end_date !== ''
              ? values?.end_date
              : null
            : null,
        po_id:
          values?.po_id !== undefined
            ? values?.po_id !== ''
              ? convertStringToList(values?.po_id)
              : null
            : null,
        loc:
          values?.loc !== undefined
            ? values?.loc !== ''
              ? values?.loc
              : null
            : null,
        department:
          values?.department !== undefined
            ? values?.department !== ''
              ? values?.department
              : null
            : null,
      }
      console.log('receiptByPORequest', receiptByPORequest)
      setShowExportSpinner(false)
      axios
        .post(
          `${env.api.baseUrl}/extract_internal_report`,
          {
            report_name: 'receipt_by_po',
            params: receiptByPORequest,
          },
          { responseType: 'blob' },
        )
        .then((res) => {
          jsFileDownload(res.data, 'ReceiptByPOReport.xlsx')
          setShowExportSpinner(true)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            autoHideDuration: 6000,
            heading: 'Export Error',
            message: 'Unable to Export data to file. Please try again.',
          })
          setShowExportSpinner(true)
        })
    },
  })

  return (
    <>
      <SectionHeader pageHeading="Receipt By PO Report" />
      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        <Card>
          <div className="hc-pa-normal hc-pa-md">
            <Form onSubmit={formik.handleSubmit}>
              <Grid.Container>
                <Grid.Item xs={4}>
                  <DatePicker
                    id="start_date"
                    label="Start Date"
                    onUpdate={(id, value) => {
                      formik.setFieldValue(id, value)
                      formik.handleChange(id, value)
                    }}
                    value={formik.values.start_date}
                    errorText={formik.errors.start_date}
                    error={
                      formik.errors.start_date !== undefined &&
                      formik.touched.start_date
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <DatePicker
                    id="end_date"
                    label="End Date"
                    onUpdate={(id, value) => {
                      formik.setFieldValue(id, value)
                      formik.handleChange(id, value)
                    }}
                    value={formik.values.end_date}
                    errorText={formik.errors.end_date}
                    error={
                      formik.errors.end_date !== undefined &&
                      formik.touched.end_date
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="vendor_id"
                    label="Enter Vendor"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.vendor_id}
                    errorText={formik.errors.vendor_id}
                    error={formik.errors.vendor_id && formik.touched.vendor_id}
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={8}>
                  <FormField
                    id="po_id"
                    label="Enter PO"
                    onChange={formik.handleChange}
                    value={formik.values.po_id}
                    errorText={formik.errors.po_id}
                    error={formik.errors.po_id && formik.touched.po_id}
                    hintText="Maximum 5 POs are allowed"
                    placeholder="Please enter comma separated POs "
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="department"
                    label="Enter Department"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                    errorText={formik.errors.department}
                    error={
                      formik.errors.department && formik.touched.department
                    }
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse" justify="space-between">
                <Grid.Item className="hc-mt-md">
                  <Button type="submit" raised style={{ height: '40px' }}>
                    Submit
                  </Button>
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FormField
                    id="loc"
                    label="Enter Loc"
                    className="inputtype"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.loc}
                    errorText={formik.errors.loc}
                    error={formik.errors.loc && formik.touched.loc}
                  />
                </Grid.Item>
              </Grid.Container>
            </Form>
          </div>
        </Card>
        <Grid.Container justify="center" align="center" className="hc-pt-4x">
          {!showExportSpinner && (
            <>
              <Grid.Item>
                <Spinner />
              </Grid.Item>
              {loadingMessage}
            </>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
