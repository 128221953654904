import {
  Button,
  Card,
  Grid,
  Layout,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'

export const LogIn = () => {
  const auth = useAuth()
  const { login } = auth

  return (
    <Layout data-testid="loginLayout" fullWidth>
      {login()}
      <Layout.Body includeRail>
        <Grid.Container
          className="praxcss-height100vh"
          align="center"
          justify="center"
        >
          <Grid.Item xs={10} md={6} lg={4}>
            <Card>
              <div className="hc-pa-normal hc-ta-center">
                <Heading>Welcome to Accounts Payable!</Heading>
              </div>
              <div className="hc-pa-normal hc-pt-none">
                <p className="hc-fs-section">Please login to access content.</p>
              </div>
              <div className="hc-pa-normal hc-pt-none">
                <Button
                  data-testid="loginButton"
                  type="primary"
                  size="expanded"
                  fullWidth
                  onClick={login}
                >
                  Log In
                </Button>
              </div>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </Layout>
  )
}

export default LogIn
