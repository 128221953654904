import {
  FormField,
  GridContainer,
  GridItem,
  Pagination,
  Table,
  Button,
  Dropdown,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useMemo } from 'react'
import { useTable, useExpanded, usePagination, useSortBy } from 'react-table'
import axios from 'axios'
import { Tooltip } from 'react-tooltip'
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { convertToUS } from '../globalComponents/Utils'
import { useEnv } from '@praxis/component-runtime-env'
import jsFileDownload from 'js-file-download'

export const CreditDebitTable = ({ tab, data = [], treeData = {} }) => {
  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        label: () => null, // No header
        id: 'id', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <FontAwesomeIcon icon={faArrowDown} />
            ) : (
              <FontAwesomeIcon icon={faArrowRight} />
            )}
          </span>
        ),
        size: 1,
      },
      ...(tab?.table_mappings ?? []),
    ],
    [tab],
  )

  const treeColumns = tab?.tree_mappings

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // tableData,
      data,
      columns,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    useExpanded,
    usePagination,
  )

  const ellipsisStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 3,
  }

  return (
    <div
      style={{
        height: '75vh',
        width: '100%',
        overflow: 'scroll',
        backgroundColor: '#fff',
      }}
    >
      <Tooltip
        id="inv-rcpt-tooltip"
        clickable
        style={{
          zIndex: 'var(--canvas-z-index--tooltip)',
        }}
        render={({ content, activeAnchor }) => (
          <div
            style={{
              padding: '15px',
              maxHeight: '25vh',
              overflow: 'scroll',
            }}
          >
            {content && (
              <div>
                {/* Split the string and render each value */}
                {content.split(',').map((value, index) => (
                  <div>{value}</div>
                ))}
              </div>
            )}
          </div>
        )}
      />
      <Table {...getTableProps}>
        <Table.Head style={{ backgroundColor: '#fff' }}>
          <strong>
            {headerGroups.map((headerGroups) => (
              <Table.Row
                {...headerGroups.getFooterGroupProps()}
                style={{ fontWeight: 'bolder' }}
              >
                {headerGroups.headers.map((column) => (
                  <Table.Header
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    xs={column?.size ?? 2}
                  >
                    {column.render('label')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Table.Header>
                ))}
              </Table.Row>
            ))}
          </strong>
        </Table.Head>
        <Table.Body>
          {page?.map((row, i) => {
            prepareRow(row)
            return (
              <>
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell?.column?.type === 'currency') {
                      return (
                        <Table.Data
                          {...cell.getCellProps()}
                          xs={cell?.column?.size}
                        >
                          <div
                            style={{
                              textAlign: 'right',
                              alignSelf: 'stretch',
                            }}
                          >
                            {'$'}
                            {cell.render('Cell')}
                          </div>
                        </Table.Data>
                      )
                    } else if (cell?.column?.type === 'date') {
                      return (
                        <Table.Data
                          {...cell.getCellProps()}
                          xs={cell?.column?.size}
                        >
                          {convertToUS(cell.value)}
                        </Table.Data>
                      )
                    } else {
                      return (
                        <Table.Data
                          {...cell.getCellProps()}
                          xs={cell?.column?.size}
                          key={cell.getCellProps().key}
                        >
                          {cell.column?.id === 'invoice_ids' ||
                          cell.column?.id === 'receipt_ids' ? (
                            cell.value?.length ? (
                              <div
                                data-tooltip-id="inv-rcpt-tooltip"
                                data-tooltip-content={cell.value}
                              >
                                <div style={ellipsisStyle}>
                                  {`${cell.value.join(', ')}`}
                                </div>
                              </div>
                            ) : (
                              ''
                            )
                          ) : (
                            cell.render('Cell')
                          )}
                        </Table.Data>
                      )
                    }
                  })}
                </Table.Row>
                <br></br>
                {row.isExpanded ? (
                  <GridContainer justify="space-around">
                    <GridItem xs={10}>
                      <InvoiceTable
                        treeData={treeData[row?.original?.document_number]}
                      />

                      <TreeTable
                        treeData={treeData[row?.original?.document_number]}
                        columns={treeColumns}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
              </>
            )
          })}
        </Table.Body>
      </Table>
      <GridContainer>
        <GridItem xs="12"></GridItem>
        <GridItem xs="3">
          <Pagination
            iconsOnly
            currentPage={pageIndex + 1}
            totalPages={pageCount}
            onRequestNext={() => {
              nextPage()
            }}
            onRequestPrev={() => {
              previousPage()
            }}
          />
        </GridItem>
        <GridItem xs="1"></GridItem>
        <GridItem xs="2">
          <FormField
            type="select"
            onUpdate={(e, a) => {
              setPageSize(Number(a))
            }}
            options={[
              { value: 10, label: 'Show 10' },
              { value: 20, label: 'Show 20' },
              { value: 50, label: 'Show 50' },
            ]}
            value={pageSize}
          ></FormField>
        </GridItem>
        <GridItem xs="3"></GridItem>
      </GridContainer>
    </div>
  )
}

const TreeTable = ({ treeData, columns }) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    data: treeData,
    columns: columns,
  })

  return (
    <div style={{ height: '25vh', width: '99%', overflow: 'scroll' }}>
      <Table {...getTableProps}>
        <Table.Head style={{ backgroundColor: '#fff', fontWeight: 'bold' }}>
          {headerGroups.map((headerGroups) => (
            <Table.Row {...headerGroups.getFooterGroupProps()}>
              {headerGroups.headers.map((column) => (
                <Table.Header {...column.getHeaderProps()} xs={column?.size}>
                  {column.render('label')}
                </Table.Header>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {rows?.map((row, i) => {
            prepareRow(row)
            return (
              <>
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell?.column?.type === 'currency') {
                      return (
                        <Table.Data
                          {...cell.getCellProps()}
                          xs={cell?.column?.size}
                        >
                          <div
                            style={{
                              textAlign: 'right',
                              alignSelf: 'stretch',
                            }}
                          >
                            {'$'}
                            {cell.render('Cell')}
                          </div>
                        </Table.Data>
                      )
                    } else {
                      return (
                        <Table.Data
                          {...cell.getCellProps()}
                          xs={cell?.column?.size}
                        >
                          {cell.render('Cell')}
                        </Table.Data>
                      )
                    }
                  })}
                </Table.Row>
              </>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

const InvoiceTable = ({ treeData }) => {
  const env = useEnv()
  const makeToast = useToaster()
  const handleExcelDownload = (vendor_i, doc_i) => {
    axios
      .post(
        `${env.api.baseUrl}/extract_report`,
        {
          report_name: 'credit_debit',
          params: { vendor_id: vendor_i, document_id: doc_i, begin_date: '' },
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        jsFileDownload(res.data, 'Report.xlsx')
      })
      .catch(() => {
        makeToast({
          type: 'error',
          autoHideDuration: 6000,
          heading: 'Export Error',
          message: 'Unable to export the data to Excel. Please try again.',
        })
      })
  }
  const handlePDFDownload = (vendor_i, doc_i) => {
    axios
      .post(
        `${env.api.baseUrl}/pdf_report`,
        {
          report_name: 'credit_debit',
          params: { vendor_id: vendor_i, document_id: doc_i, begin_date: '' },
        },
        { responseType: 'blob' },
      )
      .then((res) => {
        jsFileDownload(res.data, 'Report.pdf')
      })
      .catch(() => {
        makeToast({
          type: 'error',
          autoHideDuration: 6000,
          heading: 'Export Error',
          message: 'Unable to export the data to PDF. Please try again.',
        })
      })
  }

  return (
    <div>
      <GridItem xs={12} className={'hc-pv-none'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {
            treeData?.map((row, i) => (
              <Dropdown location="bottom-left">
                <Button type="secondary">
                  Download '{row.document_number}' Data
                </Button>
                <Dropdown.Menu>
                  <Dropdown.MenuItem
                    onClick={() =>
                      handleExcelDownload(row.vendor_id, row.document_number)
                    }
                  >
                    {' '}
                    Excel
                  </Dropdown.MenuItem>
                  <Dropdown.MenuItem
                    onClick={() =>
                      handlePDFDownload(row.vendor_id, row.document_number)
                    }
                  >
                    PDF
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            ))[0]
          }
        </div>
      </GridItem>
    </div>
  )
}
