import { NavLink } from 'react-router-dom'
import { Heading, SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react'
import { useProfile, USER, VENDOR } from './UserProfileProvider'

export const SideNavigation = ({ isOpen, onRequestClose }) => {
  const { userRole } = useProfile()
  return (
    <SideNav
      data-testid="sideNav"
      isVisible={isOpen}
      onRequestClose={onRequestClose}
    >
      <SideNav.Header
        data-testid="sideNavLogoHeader"
        as={NavLink}
        to={'/'}
        exact
      >
        <TargetLogo size="expanded" className="hc-mr-dense" />
        <Heading size={5}>Accounts Payable</Heading>
      </SideNav.Header>
      <SideNav.Navigation data-testid="sideNavMainNavArea">
        {[VENDOR, USER].includes(userRole) && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/credit_debit'}
            exact
            activeClassName="isSelected"
          >
            Credit Debit Report
          </SideNav.NavigationItem>
        )}
        {[USER].includes(userRole) && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/receipt_detail'}
            exact
            activeClassName="isSelected"
          >
            Receipt Detail Report
          </SideNav.NavigationItem>
        )}
        {[USER].includes(userRole) && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/receipt_by_po'}
            exact
            activeClassName="isSelected"
          >
            Receipt By PO Report
          </SideNav.NavigationItem>
        )}
        {[USER].includes(userRole) && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/pre_match'}
            exact
            activeClassName="isSelected"
          >
            Pre Match Report
          </SideNav.NavigationItem>
        )}
        {[USER].includes(userRole) && (
          <SideNav.NavigationItem
            as={NavLink}
            to={'/after_match'}
            exact
            activeClassName="isSelected"
          >
            After Match Report
          </SideNav.NavigationItem>
        )}
      </SideNav.Navigation>
    </SideNav>
  )
}
