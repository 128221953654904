import { useState } from 'react'
import {
  Card,
  Grid,
  Layout,
  Form,
  FormField,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { SectionHeader } from '../globalComponents/SectionHeader'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import jsFileDownload from 'js-file-download'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import * as Yup from 'yup'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const loadingPosition = css`
  height: 50vh;
`

export const PreMatchReport = () => {
  const env = useEnv()
  const [showExportSpinner, setShowExportSpinner] = useState(true)
  const [loadingMessage] = useState('Downloading Data to Excel...')
  const makeToast = useToaster()

  function convertStringToList(listOfStrings) {
    if (listOfStrings.slice(-1) === ',') {
      listOfStrings = listOfStrings.slice(0, -1)
    }
    return listOfStrings
      .split(',')
      .map((formattedString) => formattedString.trim())
      .filter((formattedString) => formattedString !== '')
  }

  const schema = Yup.object().shape(
    {
      vendor_id: Yup.number().required('Please Enter Vendor Id'),
      invoice: Yup.string()
        .required('Please Enter Invoices')
        .test('Fine', 'Please enter valid Invoice', function (value) {
          if (value !== undefined && value.slice(-1) === ',') {
            return false
          }
          return true
        })
        .test('Fine', 'Maximum 8 Invoices are allowed', function (value) {
          if (value !== undefined) {
            if (convertStringToList(value).length > 8) {
              return false
            }
          }
          return true
        }),
      receipt: Yup.string()
        .required('Please Enter Receipts')
        .test(
          'Fine',
          'Please enter valid Receipts [0-9] with comma seperated. No whitespaces allowed',
          function (value) {
            if (!value || (value !== undefined && /^[0-9,]*$/.test(value))) {
              return true
            }
            return false
          },
        )
        .test('Fine', 'Please enter valid Receipts', function (value) {
          if (value !== undefined && value.slice(-1) === ',') {
            return false
          }
          return true
        })
        .test('Fine', 'Maximum 6 Receipts are allowed', function (value) {
          if (value !== undefined) {
            if (convertStringToList(value).length > 6) {
              return false
            }
          }
          return true
        }),
    },
    ['vendor_id', 'invoice', 'receipt'],
  )

  const formik = useFormik({
    initialValues: {
      vendor_id: undefined,
      invoice: undefined,
      receipt: undefined,
      casepack: 'GMS',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: (values) => {
      console.log('request', values)
      let preMatchRequest = {
        vendor_id:
          values?.vendor_id !== undefined
            ? values?.vendor_id !== ''
              ? values?.vendor_id
              : null
            : null,
        invoice:
          values?.invoice !== undefined
            ? values?.invoice !== ''
              ? convertStringToList(values?.invoice)
              : null
            : null,
        receipt:
          values?.receipt !== undefined
            ? values?.receipt !== ''
              ? convertStringToList(values?.receipt)
              : null
            : null,
        casepack:
          values?.casepack !== undefined
            ? values?.casepack !== ''
              ? values?.casepack
              : null
            : null,
      }
      console.log(preMatchRequest)
      setShowExportSpinner(false)
      axios
        .post(
          `${env.api.baseUrl}/extract_internal_report`,
          {
            report_name: 'pre_match',
            params: preMatchRequest,
          },
          { responseType: 'blob' },
        )
        .then((res) => {
          jsFileDownload(res.data, 'PreMatchReport.xlsx')
          setShowExportSpinner(true)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            autoHideDuration: 6000,
            heading: 'Export Error',
            message: 'Unable to Export data to file. Please try again.',
          })
          setShowExportSpinner(true)
        })
    },
  })

  return (
    <>
      <SectionHeader pageHeading="Pre Match Report" />
      <Layout.Body data-testid="layoutWithRightRailMainContent" includeRail>
        <Card>
          <div className="hc-pa-normal hc-pa-md">
            <Form onSubmit={formik.handleSubmit}>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <Grid.Container>
                    <Grid.Item xs={4}>
                      <FormField
                        id="vendor_id"
                        type="number"
                        className="inputtype"
                        label="Enter Vendor Id"
                        onChange={formik.handleChange}
                        value={formik.values.vendor_id}
                        errorText={formik.errors.vendor_id}
                        error={formik.errors.vendor_id}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={12} className="hc-pt-none">
                  <Grid.Container>
                    <Grid.Item xs={8}>
                      <FormField
                        id="invoice"
                        label="Enter Invoice"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.invoice}
                        errorText={formik.errors.invoice}
                        error={formik.errors.invoice}
                        rightContent="Enter Upto 8 Invoices"
                        placeholder="Please enter comma separated invoices "
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <Grid.Container>
                    <Grid.Item xs={8}>
                      <FormField
                        id="receipt"
                        label="Enter Receipt"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.receipt}
                        errorText={formik.errors.receipt}
                        error={formik.errors.receipt}
                        rightContent="Enter Upto 6 Receipts"
                        placeholder="Please enter comma separated receipts "
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <Grid.Container>
                    <Grid.Item xs={8}>
                      <Form.Field
                        className="myClass-Horizontal"
                        id="casepack"
                        label="Casepack source"
                        type="radio"
                        options={[
                          { value: 'GMS', label: 'GMS' },
                          { value: '1', label: '1' },
                        ]}
                        onUpdate={(id, value) => {
                          formik.setFieldValue(id, value)
                          formik.handleChange(id, value)
                        }}
                        value={formik.values.casepack}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse">
                <Grid.Item>
                  <Button type="submit" raised>
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Form>
          </div>
        </Card>
        <Grid.Container justify="center" align="center" css={loadingPosition}>
          {!showExportSpinner && (
            <Grid.Item>
              <Spinner /> {loadingMessage}
            </Grid.Item>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
