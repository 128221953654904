import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { useAuth } from '@praxis/component-auth'
import { handleLogout } from '../views/AuthUtils'

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Header = ({ onSideNavToggle }) => {
  const auth = useAuth()
  const { session } = auth
  const company = session.userInfo?.company

  return (
    <>
      <Layout.MenuButton>
        <Button
          className="C-MenuButton"
          onClick={onSideNavToggle}
          iconOnly
          data-testid="headerMenuButton"
          aria-label="toggle side nav menu"
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </Layout.MenuButton>
      <Layout.Header />
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            {session.userInfo.fullName}, {company + '  '}
            <Dropdown size="dense" location="bottom-left">
              <Button
                iconOnly
                className="praxcss-header__avatar"
                data-testid="headerGlobalActionsButton"
              >
                <Avatar>{getInitials(session.userInfo)}</Avatar>
              </Button>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={() => handleLogout(auth)}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
